/* @module api-operations */
import { chain, create } from 'underscore';
import Backbone from 'backbone';

/**
 * A prototype which can be used for operations so that they can render
 * themselves.
 */
const OperationPrototype = {

    /**
     * Render a URL path for an operation.
     *
     * @param {Object<string, string>} params - A mapping of parameter names to
     *     parameter values.
     *
     * @return {string}
     */
    render: function (params) {

        let href = this.path;
        const parameters = this.parameters;
        const query = [];

        for (let name in params) {

            const param = parameters.find(p => p.name == name);

            if (param) {

                const value = params[name];

                switch (param.in) {
                    case 'path':
                        href = href.replace('{' + param.name + '}', value);
                        break;
                    case 'query':
                        query.push(name + '=' + value);
                        break;
                    default:
                        break;
                }
            }
        }

        if (query.length > 0) {
            href = href + '?' + query.join('&');
        }

        return href;
    }
};

/**
 * API operations retrieved originally from the API itself.
 *
 * Each attribute is an operation ID. Each value is an object describing
 * the operation along with a function which can render a URL for the
 * operation.
 *
 * @constructor
 */
const ApiOperations = Backbone.Model.extend({

    parse: function (response) {

        return chain(response).map((pathItem, path) => {
            return Object.keys(pathItem).map((method) => {
                return create(OperationPrototype, {
                    id:         pathItem[method].operationId,
                    path:       path,
                    method:     method,
                    parameters: pathItem[method].parameters || []
                });
            });
        }).flatten().indexBy('id').value();
    },

    /**
     * Render a URL path for an operation ID.
     *
     * @param {string} operationId - The operation ID to render.
     *
     * @param {Object<string, string>} params - A mapping of parameter names to
     *     parameter values.
     *
     * @return {string}
     */
    render: function (operationId, params) {

        if (! operationId || ! this.has(operationId)) {
            return;
        }

        return this.get(operationId).render(params);
    }
});

/**
 * A single global instance of API operations.
 */
export const apiOperations = new ApiOperations();
