import { pick } from 'underscore';
import Backbone from 'backbone';

/**
 * Model for a book of the Bible in an edition.
 */
const Book = Backbone.Model.extend({
    defaults: {
        name:         '',
        abbreviation: '',
        alternatives: [],
        sort_key:     '',
        chapters:     [],
        pericopes:    []
    },

    parse: function (response) {
        this.url = response.href;
        const data = {
            ...this.attributes,
            ...pick(response, "id", "name", "abbreviation", "alternatives",
                              "sort_key", "chapters", "pericopes")
        };
        return data;
    },

    getName:             function () { return this.get("name"); },
    getAbbreviation:     function () { return this.get("abbreviation"); },
    getAlternativeNames: function () { return this.get("alternatives"); },
    getChapters:         function () { return this.get("chapters"); },
    getPericopes:        function () { return this.get("pericopes"); }
});

export default Book;
