import { pick } from 'underscore';
import Backbone from 'backbone';
import Rubrics from '../collections/rubrics.js';

/**
 * A collection of rubrics with a title.
 *
 * Model attributes:
 *
 * - title: The register title.
 * - rubrics: A Backbone.Collection of rubrics contained in the register.
 */
const Register = Backbone.Model.extend({

    defaults: function () {
        return {
            rubrics: new Rubrics()
        };
    },

    parse: function (response) {
        return pick(response, 'id', 'title');
    },

    /**
     * Add a rubric to the register.
     *
     * @param {Rubric} rubric - The rubric to add.
     *
     * @return {this}
     */
    addRubric: function (rubric) {
        this.get('rubrics').add(rubric);
        return this;
    },

    /**
     * Set the notes contained in the register.
     *
     * The notes will be set on each rubric in the register.
     *
     * @param {Notes} notes - A Backbone.Collection of Notes.
     *
     * @return {this}
     */
    setNotes: function (notes) {

        this.get('rubrics').forEach((rubric) => {
            rubric.setNotes(notes);
        });

        return this;
    },

    /**
     * Determine whether the register contains any rubrics with notes.
     *
     * @return {Boolean}
     */
    isEmpty: function () {
        return this.get('rubrics').every(rubric => rubric.isEmpty());
    }
});

export default Register;
