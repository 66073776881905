import { once, pick } from 'underscore';
import Backbone from 'backbone';
import { apiOperations } from './api-operations.js';
import RubricTrees from './edition-rubric-trees.js';
import TableOfContents from './table-of-contents.js';

/**
 * Model for an edition of the Bible.
 *
 * Model attributes:
 *
 * - id: The siglum of the edition
 * - name: The name of the edition.
 * - lang: The language of the edition.
 * - tags: Tags which identify the edition.
 * - toc: The books in the edition. Initially an URL for requesting the
 *       the table of contents.
 * - rubrics: An URL for requesting rubric trees.
 * - rubric_trees: Groupings of rubrics for the edition into rubrics
 *       without a register and registers.
 * - fallback - The siglum of an edition from which to obtain verses not
 *       published in the main edition.
 */
const Edition = Backbone.Model.extend({

    defaults: {
        name:         '',
        lang:         '',
        tags:         [],
        toc:          '',
        rubrics:      '',
        rubric_trees: null
    },

    url: function () {
        return apiOperations.render('edition', { edition: this.id });
    },

    parse: function (response) {
        return pick(response, 'id', 'name', 'lang', 'tags', 'toc', 'rubrics');
    },

    getSiglum: function () { return this.get('id');   },
    getLang:   function () { return this.get('lang'); },
    getTags:   function () { return this.get('tags'); },
    getName:   function () { return this.get('name'); },
    toString:  function () { return this.get('id'); },

    /**
     * Get a Promise for the table of contents of the edition.
     *
     * @return {Promise} A Promise for an TableOfContents object.
     */
    fetchToc: once(function () {

        const url = this.get('toc');
        const toc = new TableOfContents({}, { edition: this });
        this.set('toc', toc);

        return Promise.resolve(toc.fetch({ url: url })).then(() => {
            return toc;
        });
    }),

    /**
     * Get a Promise for the registers and rubrics in the edition arranged
     * by part (testament).
     *
     * @return {Promise} A Promise for a RubricTrees object.
     */
    fetchRubricTrees: once(function () {

        // No need to set edition in the RubricTrees constructor since we have
        // the URL

        const url = this.get('rubrics');
        const trees = new RubricTrees();
        this.set('rubric_trees', trees);

        return Promise.resolve(trees.fetch({ url: url })).then(() => {
            return trees;
        });
    }),

    /**
     * Get a Promise for a list of rubrics.
     *
     * @return {Promise} A Promise for a Collection of Rubrics.
     */
    fetchRubrics: function () {
        return this.fetchRubricTrees().then((trees) => {
            return trees.get('rubrics').clone();
        });
    },

    /**
     * Get a Promise for a list of rubrics which can be cross referenced.
     *
     * @return {Promise} A Promise for a Collection of Rubrics.
     */
    fetchCrossReferenceTypes: function () {
        return this.fetchRubricTrees().then((trees) => {
            const types = trees.get('rubrics').clone();
            types.push(trees.get('marg_ref_type'));
            return types;
        });
    }
});

export default Edition;
