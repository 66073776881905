import Marionette from 'backbone.marionette';
import RenewSessionView from './renew-session-view.js';
import ScopeSearchInputView from './scope-search-input-view.js';

/**
 * A view which represents the layout of a page. View for navigation,
 * content, etc., may be added to this view.
 */
export default Marionette.View.extend({

    el: 'body',

    template: false,

    ui: {
        scopeAndSearch: '#bible-search',
        logoutControl: '#user .logout'
    },

    events: {
        'click @ui.logoutControl': 'signInOrOut'
    },

    /**
     * Initialize the view.
     *
     * @param {Object} options - Marionette.View options, including common
     *     options such as user used in views across the application.
     *
     * @param {User} options.user - A user model, which becomes the model
     *     for the view.
     */
    initialize: function (options) {
        this.model = options.user;
        this.renewSessionView = new RenewSessionView(options);
        if (this.getUI('scopeAndSearch').length != 0) {
            this.scopeSearchInputView = new ScopeSearchInputView({
                ...options,
                ...{ el: this.getUI('scopeAndSearch') }
            });
        }
    },

    /**
     * When the login control is clicked, if we have a user, logout the
     * user and remove the user from the model.
     */
    signInOrOut: function (event) {

        if (this.model && this.model.get('id')) {

            event.preventDefault();

            this.listenToOnce(this.model, 'logout', () => {
                window.location.reload();
            });

            this.model.logout();
        }
    }
});
