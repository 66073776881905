import Backbone from 'backbone';
import Rubrics from './rubrics.js';

/**
 * A tree of registers and rubrics.
 *
 * Each item in the collection is either a Rubric without a register or
 * a Register containing a rubric list.
 *
 * The tree may be associated with a collection of notes which will be
 * passed along to the registers and rubrics.
 */
export default Backbone.Collection.extend({

    /**
     * Add notes to each of the rubrics and registers contained in the
     * tree.
     *
     * @param {Notes} notes - A Backbone.Collection of Notes.
     *
     * @return {this}
     */
    setNotes: function (notes) {
        this.forEach(group => group.setNotes(notes));
        return this;
    },

    /**
     * Get a list of all rubrics contained in the tree.
     *
     * @return {Rubrics}
     */
    getRubrics: function () {
        return new Rubrics(this.map((model) => {
            if (model.has('rubrics')) {
                return model.get('rubrics').toArray();
            } else {
                return model;
            }
        }).flat());
    }
});
