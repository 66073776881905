/**
 * @fileOverview Startup code for all pages on the site, run when the
 * DOM is ready.
 */
import $ from 'jquery';
import featureDetect from './init/feature-detect.js';
import initializeBibleNavigation from './init/bible-navigation.js';
import initializeLanguageChange from './init/lang-change.js';
import initializeUserMenus from './init/user-menus.js';
import './init/backbone-debug.js';

$(() => {
    featureDetect();
    initializeUserMenus();
    initializeLanguageChange();
    initializeBibleNavigation();
});
