import { pick } from 'underscore';
import Backbone from 'backbone';
import Book from './book.js';

function findBook(name) {
    return this.get('books').find(
        book => book.getName() == name || book.getAbbreviation() == name
    );
}

/**
 * Model for a part (testament) of the Bible.
 */
const Part = Backbone.Model.extend({

    defaults: {
        name:      '',
        books:     [],
        groupings: []
    },

    parse: function (response) {
        return pick(response, 'name', 'books', 'groupings');
    },

    getBooks:     function () { return this.get('books'); },

    findBook: findBook,

    getGroupings: function () { return this.get('groupings'); }
});

/**
 * Model for a table of contents for an edition of the Bible, a list
 * of books and parts into which the books are grouped.
 */
const TableOfContents = Backbone.Model.extend({

    defaults: {
        books: [],
        parts: []
    },

    initialize: function (attributes, options) {
        this.edition = options.edition;
    },

    parse: function (response) {

        const books = response.books.map((book) => {
            return new Book(book, { parse: true });
        });

        const parts = response.parts.map((part) => {
            part.books = part.books.map((abbr) => {
                return books.find(book => book.getAbbreviation() == abbr);
            });
            return new Part(part, { parse: true });
        });

        return {
            books: books,
            parts: parts
        };
    },

    getPart: function (part) {
        switch (part) {
            case 'at':
            case 'ot':
                return this.get('parts')[0];
            case 'nt':
                return this.get('parts')[1];
        }
    },

    findBook: findBook,

    getBooks: function () { return this.get('books'); },

    findPartIdForBook: function (name) {

        if (this.getPart('ot').findBook(name)) {
            return 'ot';
        }

        if (this.getPart('nt').findBook(name)) {
            return 'nt';
        }
    },

    getBookAbbreviations: function () {
        return this.getBooks().map(book => book.getAbbreviation());
    }
});

export default TableOfContents;
