import BibleNavigation from '../../views/bible-navigation/index.js';
import Config from '../../config.js';

/**
 * Initialize Bible navigation.
 */
export default function () {

    const config = Config();
    let bibleNavigation;

    if (config.bible_navigation && config.bible_navigation.target &&
        config.edition) {

        config.bible_navigation.edition = config.edition;
        bibleNavigation = new BibleNavigation(config.bible_navigation);
        bibleNavigation.attach();
    }
}
