/**
 * @fileOverview Check for minimal JavaScript features required for the
 * site.
 *
 * jQuery >= 2 works only with IE >= 9.
 *
 * TinyMCE >= 4.6 will not work with IE < 11. Flexbox detection should
 * handle this.
 */
import 'modernizr';

export default function () {

    // Test for Function.prototype.bind which will not be found in IE < 9
    if (! window.Modernizr.es5function || ! Modernizr.flexbox) {
        browserOutOfDate();
    }
}

function browserOutOfDate() {

    // Do not use any helpers like jQuery. Use only the most basic
    // JavaScript for maximum compatibility

    let lang = document.documentElement.getAttribute('lang');

    if (! lang) {
        lang = 'en';
    }

    import(`languages/messages.${lang}.yaml`).then((messages) => {
        const message = document.createTextNode(messages['browser.too.old']);
        const el = document.createElement('div');
        el.setAttribute('class', 'old-browser-message');
        el.appendChild(message);
        document.body.insertBefore(el, null);
    });
}
