import { pick } from 'underscore';
import Backbone from 'backbone';
import store from 'store2';
import { apiOperations } from './api-operations.js';

/**
 * Model for a user of the site.
 *
 * A user does not necessarily represent the user currently signed in to
 * the site.
 *
 * # Properties
 *
 * - id: The user ID.
 * - name: The full name of the user.
 * - lastname: The surname of the user, useful for sorting.
 * - firstname: The given name of the user.
 * - lang: The default language of the user.
 * - email: The email address of the user.
 * - roles: A list of roles the use performs: admin, contributor, scribe.
 * - is_active: Whether or not the user is is active.
 * - is_approved: Whether or not the user is is approved.
 * - password: The user password, used only when signing in to the site
 *       or when setting the user password when editing the user.
 * - permissions: The user permissions.
 * - jwt: A JWT for user authentication.
 * - ttl: The TTL in seconds of the JWT, fetched with the JWT
 * - exp: The expiration time of the JWT represented as a UNIX timestamp,
 *       calculated from the TTL.
 *
 * # Custom Events
 *
 * - logout: Triggered after a successful logout.
 *
 * @class User
 */
const User = Backbone.Model.extend({

    /**
     * Initialize the user.
     *
     * @param {Object} attributes - Backbone.Model attributes.
     */
    initialize: function (attributes) {
        if (attributes.jwt) {
            this.tapSync();
        }
    },

    urlRoot: function () {
        // 'user-create' generates the root URL to which a user ID may be
        // appended for get/write operations.
        return apiOperations.render('user-create');
    },

    /**
     * Return `true` if the user is an administrator.
     *
     * This will only work if user roles have been fetched.
     *
     * @return {boolean}
     */
    isAdministrator: function () {
        return this.has('roles') &&
            this.get('roles').some(role => role == 'admin');
    },

    /**
     * Return `true` if the user is a contributor.
     *
     * This will only work if user roles have been fetched.
     *
     * @return {boolean}
     */
    isContributor: function () {
        return this.has('roles') &&
            this.get('roles').some(role => role == 'contributor');
    },

    /**
     * Return `true` if the user is a scribe.
     *
     * This will only work if user roles have been fetched.
     *
     * @return {boolean}
     */
    isScribe: function () {
        return this.has('roles') &&
            this.get('roles').some(role => role == 'scribe');
    },

    /**
     * Attempt to sign in as a user.
     *
     * All model attributes will be cleared except email and password.
     *
     * @return {jqXHR}
     */
    login: function () {

        this.urlRoot = apiOperations.render('login', {});
        const loginData = this.pick('email', 'password');
        this.clear();

        // Unset the password once we have successfully signed in and store
        // the user and JWT information in localStorage.
        this.listenToOnce(this, 'sync', function () {
            this.unset('password');
            this.refreshJwtExp();
            this.tapSync();
        });

        // ...but if there is an error, remove the callback and restore
        // the other attributes
        this.listenToOnce(this, 'error', function () {
            this.stopListening(this, 'sync');
            this.set(this.previousAttributes());
        });

        return this.save(loginData);
    },

    /**
     * Refresh user credentials using the current credentials.
     */
    keepalive: function () {

        // Rely on tapped version of Backbone.sync to handle credentials
        return this.sync('patch', this, {
            url:   apiOperations.render('keepalive', {}),
            attrs: {}
        });
    },

    /**
     * Attempt to sign out a user.
     *
     * All model attributes will be cleared.
     *
     * @return {jqXHR}
     */
    logout: function () {

        this.urlRoot = apiOperations.render('logout', {});
        this.clear();

        // Remove the user from localStorage.

        this.listenToOnce(this, 'sync', function () {
            store.remove('user');
            this.untapSync();
            this.trigger('logout');
        });

        // ...but if there is an error, remove the callback and restore
        // all attributes
        this.listenToOnce(this, 'error', function () {
            this.stopListening(this, 'sync');
            this.set(this.previousAttributes());
        });

        return this.save({});
    },

    /**
     * Calculate the expiration time of the JWT based on the TTL.
     */
    refreshJwtExp: function () {
        this.set('exp', Math.floor(Date.now() / 1000) + this.get('ttl'));
        store.set('user', this.pick('id', 'name', 'jwt', 'exp'));
    },

    /**
     * Get a Promise for the user permissions.
     *
     * If the user permissions have already been fetched, the function will
     * return immediately with a resolved promise. Otherwise, the promise will
     * resolve when the permissions have been fetched.
     *
     * User permissions are not currently kept in cookie/local storage due to
     * their unpredictable size.
     */
    fetchPermissions: function () {

        if (this.has('permissions')) {
            return Promise.resolve(this.get('permissions'));
        }

        return Promise.resolve(this.fetch({
            url: apiOperations.render('user-permissions', { id: this.id })
        })).then(() => this.get('permissions'));
    },

    /**
     * Add jQuery.ajax beforeStart and success callbacks which tap into
     * every Backbone sync call in order to send the current JWT on requests
     * and update the JWT for responses.
     */
    tapSync: function () {

        const sync = Backbone.sync;
        const user = this;

        user.untapSync = function () {
            Backbone.sync = sync;
        };

        Backbone.sync = function (method, model, options) {
            const beforeSend = options.beforeSend;
            const success = options.success;

            options.beforeSend = function (xhr) {

                xhr.setRequestHeader('Authorization',
                                     'Bearer ' + user.get('jwt'));

                if (beforeSend) {
                    return beforeSend.apply(this, arguments);
                }
            };

            // jQuery 1.5+ allows success to be an array of functions, but
            // Backbone does not seem to allow for this possibility, so
            // treat success as a single function

            options.success = function (data, status, xhr) {

                const auth = xhr.getResponseHeader('Authentication-Info');
                const matches = /^refresh=([^,]+),ttl=(\d+)$/.exec(auth);

                if (matches) {
                    user.set('jwt', matches[1]);
                    user.set('ttl', matches[2] - 0);
                    user.refreshJwtExp();
                }

                if (success) {
                    return success.apply(this, arguments);
                }
            };

            return sync(method, model, options);
        };
    }
}, {

    /**
     * When querying for fields, the list of all fields other than default.
     */
    allFields: [
        'lastname',
        'firstname',
        'lang',
        'email',
        'roles',
        'is_active',
        'is_approved'
    ],

    /**
     * Create a user and load the user's id and name from localStorage. If no
     * user is stored, the returned user will be a new, empty model.
     *
     * @return {User}
     */
    createFromStorage: function () {

        let attrs = store.get('user', {});

        if (! attrs.exp || Date.now() > attrs.exp * 1000) {
            store.remove('user');
            attrs = {};
        }

        if (attrs.id) {

            // Check for a cookie with the ID and name of a test user set by
            // automated tests, which use only cookies for user sessions.

            const cookie = document.cookie.split(/;\s*/)
                .find(row => row.startsWith('TESTUSER='));

            if (cookie) {

                const cookieAttrs = JSON.parse(cookie.split('=')[1]);

                // If the cookie exists but the user ID does not match the
                // user ID pulled from storage, replace the user in storage
                // with the user from the cookie but keep the JWT token.

                if (cookieAttrs.id != attrs.id) {
                    attrs.id = cookieAttrs.id;
                    attrs.name = cookieAttrs.name;
                    store.set('user', pick(attrs, 'id', 'name', 'jwt', 'exp'));
                }
            }
        }

        return new User(pick(attrs, 'id', 'name', 'jwt', 'exp'));
    }
});

export default User;
