import Backbone from 'backbone';
import config from '../config.js';
import Edition from '../models/edition.js';

/**
 * A collection of editions of the Bible.
 */
export default Backbone.Collection.extend({

    model: Edition,

    url: function () {
        return config().editions_url;
    }
});
